const settingInitialState = {
    captian: "",
    admiral: "",
    pirate: "",
    narwhal: "",
    beluga: "",
    fish: "",
    topDonator: "",
    topDonation: "",
    forEachItem: "",
    forEachDonation: "",
    forEachShare: "",
    forEachOrganization: "",
};
export default settingInitialState;