export const LOGOUT = 'LOGOUT';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_FEES = 'UPDATE_FEES';
export const UPDATE_USER_LANGUAGE = 'UPDATE_USER_LANGUAGE';
export const UPDATE_CURRENCY_PRICE = 'UPDATE_CURRENCY_PRICE';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const UPDATE_XP = 'UPDATE_XP';
export const UPDATE_RANK = 'UPDATE_RANK';
export const UPDATE_STATEID = 'UPDATE_STATEID';
export const UPDATE_SALES_TAX = 'UPDATE_SALES_TAX';
export const ACTIVE_ORGANIZATION = 'ACTIVE_ORGANIZATION';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_CURRENT_COUNTRY_SORT = 'SET_CURRENT_COUNTRY_SORT';
export const SET_DISTANCE = 'SET_DISTANCE';
export const UPDATE_LAT_LONG = 'UPDATE_LAT_LONG';
export const UPDATE_PRODUCT_COUNT = 'UPDATE_PRODUCT_COUNT';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const SET_SET_MAP_LOCK = 'SET_SET_MAP_LOCK';
export const SET_CHANGE_SLIDER = 'SET_CHANGE_SLIDER';
export const SET_ACCOUNT_ADD = 'SET_ACCOUNT_ADD';
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL';
export const SET_All_ADS = 'SET_All_ADS';
