export const UPDATE_ORGANIZATION_BANK_DETAILS = "UPDATE_ORGANIZATION_BANK_DETAILS";



























